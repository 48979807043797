$brown : #382A1F;
$yellow : #F9AC36;
$cream : #F8ECEC;
$green : #6C8685;
$dark-green : #294F50;

.condition-container {
    background-color: $cream;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.condition-box{
    background-color: white;
    width : 70%;
}

h1 {
    font-family: 'Prompt-Medium';
    margin-top: 5%;
    color: $dark-green;
}

.condition-list {
    display: flex;
    flex-flow: column;
    margin: 5% 10%;
    text-align: left;
}

.topic {
    font-size: 16px;
    font-family: 'CSPraJd-Bold';
    line-height: 30px;
}

p {
    font-family: 'CSPraJad';
    font-size: 14px;
}

.checkbox {
    
}

.checkmark {
    height: 20px;
    width: 20px;
    margin: 10px;
  }
