$brown : #382A1F;
$yellow : #F9AC36;
$cream : #F8ECEC;
$green : #6C8685;
$dark-green : #294F50;


#card {
    body {
        background: #f2f2f2;
        font-family: 'proxima-nova-soft', sans-serif;
        font-size: 14px;
    }

    .post-module {
        position: relative;
        display: block;
        background: #FFFFFF;
        min-width: 270px;
        height: 400px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
        transition: all 0.3s linear 0s;
    }

    .post-module:hover,
    .hover {
        box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
    }

    .post-module:hover .thumbnail img,
    .hover .thumbnail img {
        transform: scale(1.1);
        opacity: .6;
    }

    .thumbnail {
        background: #000000;
        height: 300px;
        overflow: hidden;
    }

    .date {
        position: absolute;
        top: 20px;
        right: 20px;
        background: #e74c3c;
        width: 55px;
        height: 55px;
        padding: 12.5px 0;
        border-radius: 100%;
        color: white;
        font-weight: 700;
        text-align: center;
        box-sizing: border-box;
    }

    // .day {
    //     font-size: 18px;
    // }

    // .post-module .thumbnail .date .month {
    //     font-size: 12px;
    //     text-transform: uppercase;
    // }

    .post-module .thumbnail img {
        display: block;
        width: 100%;
        transition: all 0.3s linear 0s;
    }

    .post-module .post-content {
        position: absolute;
        bottom: 0;
        background: #FFFFFF;
        padding: 30px;
        box-sizing: border-box;
        transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    }

    .post-module .post-content .category {
        position: absolute;
        top: -34px;
        left: 0;
        background: #e74c3c;
        padding: 10px 15px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .post-module .post-content .title {
        margin: 0;
        padding: 0 0 10px;
        color: $brown;
        font-size: 22px;
        font-weight: 500;
    }

    .post-module .post-content .sub_title {
        margin: 0;
        padding: 0 0 20px;
        color: $dark-green;
        font-size: 16px;
        line-height: 25px;
        font-family: 'CSPraJad';
        font-weight: 550;
    }

    .post-module .post-content .description {
        display: none;
        color: #666666;
        font-size: 14px;
        line-height: 1.8em;
    }


    .hover .post-content .description {
        display: block !important;
        height: auto !important;
        opacity: 1 !important;
    }

    .container {
        max-width: 320px;
        min-width: 280px;
        margin: 10px 30px;
    }

//     .container:before,
//     .container:after {
//         content: '';
//         display: block;
//         clear: both;
//     }

//     .container .column {
//         width: 20%;
//         padding: 0 25px;
//         -moz-box-sizing: border-box;
//         box-sizing: border-box;
//         float: left;
//     }

//     .container .column .demo-title {
//         margin: 0 0 15px;
//         color: #666666;
//         font-size: 18px;
//         font-weight: bold;
//         text-transform: uppercase;
//     }

//     .container .info {
//         width: 300px;
//         margin: 50px auto;
//         text-align: center;
//     }

//     .container .info h1 {
//         margin: 0 0 15px;
//         padding: 0;
//         font-size: 24px;
//         font-weight: bold;
//         color: #333333;
//     }

//     .container .info span {
//         color: #666666;
//         font-size: 12px;
//     }

//     .container .info span a {
//         color: #000000;
//         text-decoration: none;
//     }

//     .container .info span .fa {
//         color: #e74c3c;
//     }
}