.notification{
    text-align: center;
    height: 700px;
    display: flex;
    flex-flow: column;
    align-items: center;
    
    h2{
        font-family: 'Prompt-Medium';
        margin-top: 160px;
        margin-bottom: 50px;
    }

    h3 {
        font-family: 'Prompt';
        margin-top: 15px;
    }

    .location {
        color: #F9AC36;
        font-size: 30px;
    }

    p {
        font-family: 'CSPraJad';
        font-size: 18px;
    }

    .button-list {
        margin: 40px;
        margin-bottom: 40px;
    }
    
}