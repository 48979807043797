#articles{
    h1{
        font-size: 41px;
        margin-top: 80px;
        margin-bottom: 55px;
        text-align: center;
        font-family: 'Prompt-Medium';
    }
    .cards{
        display:flex;
        justify-content: center;
        flex-flow: row wrap;
        width: 100%;
    }
}
