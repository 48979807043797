#videos {
    margin: 50px 50px 50px 50px;
   > h1 {
       margin-left: 37px;
       font-family: 'Prompt-Medium';

       font-size: 41px;
        margin-top: 80px;
        margin-bottom: 30px;
        font-family: 'Prompt-Medium';
   }

    .video {
        display: inline-block;
        width: 100%;
        margin-left: 37px;
    }

   .title {
       border-bottom: 2px solid;
   }

   .row {
       justify-content: center;
       display: flex;
       flex-flow: row;
   }

   .split {
       display: flex;
       flex-flow: column;
   }


   .split-left {
        left: 0px;
        width: 50%;
   }

   .split-right {
        position: absolute;
        right: 0px;
        width: 50%;
   }
   
   .btn {
       align-items: center;
   }

}