#ads {
    align-items: center;
    height: 255px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    width: auto;
}