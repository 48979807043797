#trade {
    .page{
        text-align: center;
    }

    h1 {
        text-align: center;
        font-family: 'Prompt-Medium';
        font-size: 48px;
    }

    h2 {
        text-align: center;
    }

    .cards {
        display: inline-flex;
        flex-flow: row wrap;
        justify-content: space-around;
        width: 100%;
        margin: 40px 0px;
    }

    .search {
        display: flex;
        width: 70%;
        margin-right: auto;
        margin-left: auto;
    }

    .searchBox {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 13px;
        opacity: 1;
        flex: 1;
        text-align: center;
        height: 48px;
        width: 100%;
    }

    .form-control {
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        justify-content: center;
        width: 774px;
        height: 48px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 13px;
        opacity: 1;
    }
}