.content {
    text-align: center;
    p{
        font-size: 21px;
    }
    h1{
        font-size: 40px;
    }
    iframe {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}